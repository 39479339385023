import { Lock } from "@ignite-analytics/icons";
import { formatValue } from "@ignite-analytics/locale";
import { Stack, Tooltip, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { DataGridColumnFragment } from "@/gql/graphql";

import { MonetaryAmount } from "../../../../../components/Columns/MonetaryAmount";
import { AssessmentField } from "../../../Components/AssessmentField";
import { TableClassificationField } from "../../../Components/ClassificationField";

import { ColumnDefinition } from "./columnDefinition";
import { DragIndicator } from "./DragIndicator";

/**
 * Static columns are columns that cannot be edited by the user.
 *
 * Returns a partial column definition for the static columns, otherwise null.
 */
export function getNonEditableColumn(
    column: DataGridColumnFragment,
    currency: string
): Partial<ColumnDefinition> | null {
    if (
        column.type !== "AGGREGATION" &&
        column.type !== "SPEND" &&
        column.type !== "CLASSIFICATION" &&
        column.type !== "ASSESSMENT_STATUS" &&
        column.type !== "ASSESSMENT_SCORE" &&
        column.type !== "MONETARY_AMOUNT"
    )
        return null;

    const currencyString = currency ? ` (${currency})` : "";
    const headerText = column.name + currencyString;
    const minWidth = column.type === "SPEND" ? Math.max(150, headerText.length * 10) : undefined;

    return {
        sortable: column.type !== "CLASSIFICATION",
        minWidth,
        renderCell: (params) => {
            let cell = null;
            switch (column.type) {
                case "AGGREGATION":
                case "SPEND": {
                    cell = (
                        <Typography sx={{ textAlign: "right", width: "100%" }} variant="textSm">
                            {formatValue(params.value, 0)}
                        </Typography>
                    );
                    break;
                }
                case "CLASSIFICATION": {
                    if (column.typeOptions?.__typename === "ClassificationOptions") {
                        const valueById = new Map(column.typeOptions.groups?.map((g) => [g.id, g.value]));
                        cell = <TableClassificationField ids={params.value} valueById={valueById} />;
                        break;
                    }
                    break;
                }
                case "ASSESSMENT_STATUS": {
                    if (params.value === null || params.value === undefined) {
                        break;
                    }
                    cell = <AssessmentField status={params.value} />;
                    break;
                }
                case "ASSESSMENT_SCORE": {
                    if (params.value === null || params.value === undefined) {
                        break;
                    }
                    cell = params.value;
                    break;
                }
                case "MONETARY_AMOUNT": {
                    cell = <MonetaryAmount value={params.value} />;
                    break;
                }
            }
            return (
                <Tooltip placement="top" title={<Lock fontSize="small" />}>
                    <Stack width="100%">{cell}</Stack>
                </Tooltip>
            );
        },
        renderHeader: (params) => {
            return (
                <Tooltip
                    placement="top"
                    title={
                        <FormattedMessage
                            defaultMessage="This column is read-only"
                            description="Supplier table column header tooltip"
                        />
                    }
                >
                    <Stack direction="row" alignItems="center" width="100%">
                        <DragIndicator />
                        <Typography fontWeight={500} variant="inherit">
                            {params.colDef?.headerName}
                            {column.type === "SPEND" && currencyString}
                        </Typography>
                    </Stack>
                </Tooltip>
            );
        },
    };
}
