import { useQuery } from "@apollo/client";
import {
    DataGridProProps,
    GridCallbackDetails,
    GridColumnResizeParams,
    GridDensity,
    GridPaginationModel,
    GridPinnedColumns,
    GridRowSelectionModel,
    GridSortModel,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import React, { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import {
    Onboarding_UserFragment,
    OnboardingStatus,
    SortDirection,
    SupplierTable_ContactFragment,
    TableConfigsMenuButton_SupplierTableConfigFragment,
} from "@/gql/graphql";
import { useAlert } from "@/providers";

import { CONTACT_COLUMN_ID, ONBOARDING_COLUMN_ID } from "../../../../components";
import { FilterInput } from "../../../InlineFilter/types";
import { NAME_COLUMN_ID, POLLING_INTERVAL_OFF, POLLING_INTERVAL_ON, SELECT_SUPPLIER_COLUMN_ID } from "../../constants";
import { HandleOpenUpsertContactModal } from "../../SupplierTable";
import {
    defaultInitialState,
    defaultPinnedColumns,
    getLastUsedTableViewId,
    setFixedColumnsPinned,
    StoredTableState,
} from "../../tableUtils";

import { useDataGridColumns } from "./useDataGridColumns";
import { ColumnDefinition } from "./useDataGridColumns/columnDefinition";
import { useProcessRowUpdate } from "./useProcessUpdateRow";
import { useRows } from "./useRows";
import { useSupplierContacts } from "./useSupplierContacts";

export interface TableData {
    id: string;
    name: string;
    organizationNumber?: string;
    country?: string | null;
    onboardingStatus?: OnboardingStatus | null;
    onboardingApprover?: Onboarding_UserFragment | null;
    [CONTACT_COLUMN_ID]: SupplierTable_ContactFragment[];
    [key: string]: string | undefined | null | SupplierTable_ContactFragment[] | Onboarding_UserFragment;
}

graphql(`
    fragment UseTableData_supplierFields on Supplier {
        id
        name
        country
        orgNumber
        customFields {
            id
            fieldId
            name
            fieldType
            dataJson
        }
        onboarding {
            status
            approverId
        }
    }
`);

const getSuppliersDocument = graphql(`
    query UseTableData_GetSuppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                ...UseTableData_supplierFields
            }
            total
        }
    }
`);

export type ManagedDataGridProps = Pick<
    DataGridProProps<TableData>,
    | "onPaginationModelChange"
    | "paginationModel"
    | "onSortModelChange"
    | "sortModel"
    | "density"
    | "loading"
    | "rows"
    | "columns"
    | "rowCount"
    | "initialState"
    | "rowSelectionModel"
    | "onRowSelectionModelChange"
    | "processRowUpdate"
    | "onProcessRowUpdateError"
    | "onColumnOrderChange"
    | "onColumnResize"
    | "onColumnVisibilityModelChange"
    | "onPinnedColumnsChange"
    | "pinnedColumns"
    | "columnVisibilityModel"
    | "apiRef"
>;

export interface ActionBarProps {
    density: GridDensity;
    onDensityChange: React.Dispatch<React.SetStateAction<GridDensity>>;
    filter: FilterInput[];
    onFilterChange: (state: GridInitialStatePro, filterInput: FilterInput[]) => void;
    onNameSearchChange: React.Dispatch<React.SetStateAction<string>>;
    selectedSuppliers: GridRowSelectionModel;
    onDeleteSuppliers: () => void;
    suppliersDataPollActive: boolean;
    onToggleSuppliersDataPoll: VoidFunction;
    total: number;
}

interface UseSupplierTableReturnType {
    dataGridProps: ManagedDataGridProps;
    actionBarProps: ActionBarProps;
    filter: FilterInput[];
    setFilter: React.Dispatch<React.SetStateAction<FilterInput[]>>;
    supplierTableMeta: ReturnType<typeof useDataGridColumns>["supplierTableMeta"];
    views: TableConfigsMenuButton_SupplierTableConfigFragment[];
    refetchSupplierContacts: VoidFunction;
}

/**
 * useSupplierTable returns all the props that we manage for the Data Grid, and associated components.
 *
 * It fetches and transforms the data from the GraphQL API, and provides the props for the Data Grid.
 */
export function useSupplierTable(
    onAddColumn: () => void,
    handleOpenUpsertContactModal: HandleOpenUpsertContactModal,
    isEditor: boolean,
    currentState: StoredTableState | undefined,
    onStateChange: (state: StoredTableState) => void,
    baseFilter: FilterInput[],
    isOnboarding?: boolean
): UseSupplierTableReturnType {
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100,
    });

    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const apiRef = useGridApiRef();
    const setView = (state: StoredTableState) => {
        apiRef.current?.restoreState?.({
            ...state,
            preferencePanel: { open: false },
            filter: { filterModel: { items: [] } },
        });
        if (Array.isArray(state.filter)) {
            setFilter(state.filter);
        } else {
            setFilter([]);
        }
        onStateChange(state);
    };

    const {
        columns = [],
        loading: loadingColumns,
        supplierTableMeta,
        views,
    } = useDataGridColumns(onAddColumn, handleOpenUpsertContactModal, isEditor, setView);
    const configuredColumns: ColumnDefinition[] = columns.map((c) => {
        return { ...c, width: currentState?.columns?.dimensions?.[c.field]?.width ?? c.width };
    });

    const initialState = useMemo(() => {
        const v = views?.find((view) => view.id === getLastUsedTableViewId());
        return v ? (JSON.parse(v.state) as StoredTableState) : defaultInitialState;
    }, [views]);

    const [sort, setSort] = useState<{ direction: SortDirection; columnId: string }>();
    const [density, setDensity] = useState<GridDensity>("compact");
    const [filter, setFilter] = useState<FilterInput[]>(
        Array.isArray(initialState?.filter) ? (initialState?.filter ?? []) : []
    );

    const [nameSearch, setNameSearch] = useState("");
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    const [suppliersDataPollInterval, setSuppliersDataPollInterval] = useState<number>(POLLING_INTERVAL_ON);
    const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>(
        initialState?.pinnedColumns ?? defaultPinnedColumns
    );

    const onToggleSuppliersDataPoll = useCallback(() => {
        setSuppliersDataPollInterval((interval) =>
            interval === POLLING_INTERVAL_ON ? POLLING_INTERVAL_OFF : POLLING_INTERVAL_ON
        );
    }, [setSuppliersDataPollInterval]);

    const {
        data,
        loading: loadingSuppliers,
        fetchMore,
        refetch: refetchSuppliers,
    } = useQuery(getSuppliersDocument, {
        pollInterval: suppliersDataPollInterval,
        variables: {
            input: {
                sort,
                pageRange: paginationModel.pageSize,
                pageIndex: paginationModel.page * paginationModel.pageSize,
                filterJson: JSON.stringify([...baseFilter, ...filter]),
                nameSearch,
            },
        },
    });

    const contactsQueryVars = useMemo(() => {
        const supplierIds = data ? data.getSuppliers.suppliers.map((supplier) => supplier.id) : [];
        return {
            input: { supplierIds },
        };
    }, [data]);

    const { supplierContacts, refetch } = useSupplierContacts(contactsQueryVars, {
        skip: !data || data.getSuppliers.suppliers.length === 0,
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get supplier contacts",
                    description: "Alert message when supplier contacts fail to load",
                }),
                severity: "error",
            });
        },
    });

    const refetchSupplierContacts = useCallback(() => {
        refetch(contactsQueryVars);
    }, [refetch, contactsQueryVars]);

    const onPaginationModelChange = useCallback(
        function onPaginationModelChange(model: GridPaginationModel) {
            setPaginationModel(model);
            fetchMore({
                query: getSuppliersDocument,
                variables: {
                    input: {
                        sort,
                        pageRange: model.pageSize,
                        pageIndex: model.page * model.pageSize,
                        filterJson: JSON.stringify([...baseFilter, ...filter]),
                        nameSearch,
                    },
                },
            });
        },
        [fetchMore, sort, baseFilter, filter, nameSearch]
    );

    // TODO: Handle initial sort model
    const onSortModelChange = useCallback(
        function onSortModelChange(sortModel: GridSortModel, details: GridCallbackDetails) {
            // We only handle a single sort, so length === 0 for us means no sort
            if (sortModel.length === 0) {
                setSort(undefined);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onStateChange({ ...(details as any).api.exportState(), sorting: { sortModel: [] }, filter });
            } else {
                // We only handle a single column, so we just fetch the first one
                const [{ field, sort }] = sortModel;
                switch (sort) {
                    case "asc":
                        setSort({ direction: "ASC", columnId: field });
                        break;
                    case "desc":
                        setSort({ direction: "DESC", columnId: field });
                        break;
                    default:
                        setSort(undefined);
                }

                onStateChange({
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(details as any).api.exportState(),
                    sorting: { sortModel: [{ field: field, sort: sort }] },
                    filter,
                });
            }
        },
        [filter, onStateChange]
    );
    const onColumnOrderChange = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (_: any, __: any, details: GridCallbackDetails) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onStateChange({ ...(details as any).api.exportState(), filter }),
        [filter, onStateChange]
    );
    const onColumnResize = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (params: GridColumnResizeParams, _: any, details: GridCallbackDetails) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const currentState = (details as any).api.exportState();
            onStateChange({
                ...currentState,
                columns: {
                    ...currentState.columns,
                    dimensions: {
                        ...currentState.columns.dimensions,
                        [params.colDef.field]: {
                            width: params.colDef.width,
                        },
                    },
                },
                filter,
            });
        },
        [filter, onStateChange]
    );
    const onColumnVisibilityModelChange = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (_: any, details: GridCallbackDetails) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onStateChange({ ...(details as any).api.exportState(), filter }),
        [filter, onStateChange]
    );

    const onPinnedColumnsChange = useCallback(
        (pinnedColumns: GridPinnedColumns, details: GridCallbackDetails) => {
            const newPinnedColumns = setFixedColumnsPinned(pinnedColumns);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const state = (details as any).api.exportState();
            setPinnedColumns(newPinnedColumns);
            const newState = { ...state, pinnedColumns: newPinnedColumns, filter };
            onStateChange(newState);
        },
        [filter, onStateChange]
    );

    const setFilterAndStoreState = useCallback(
        (state: GridInitialStatePro, newFilter: FilterInput[]) => {
            setFilter(newFilter);
            const newState = { ...state, filter: newFilter as FilterInput[] }; //needs to take state instead of using initialState
            onStateChange(newState);
        },
        [onStateChange]
    );

    const handleRefetchSuppliers = useCallback(() => {
        refetchSuppliers({
            input: {
                sort,
                pageRange: paginationModel.pageSize,
                pageIndex: paginationModel.page * paginationModel.pageSize,
                filterJson: JSON.stringify([...baseFilter, ...filter]),
                nameSearch,
            },
        });
    }, [refetchSuppliers, sort, paginationModel.pageSize, paginationModel.page, baseFilter, filter, nameSearch]);

    const onDeleteSuppliers = useCallback(() => {
        setRowSelectionModel([]);
        handleRefetchSuppliers();
    }, [handleRefetchSuppliers, setRowSelectionModel]);

    const { processRowUpdate, onProcessRowUpdateError } = useProcessRowUpdate(handleRefetchSuppliers, isOnboarding);
    const sortModel = toSortModel(sort);

    const rows = useRows(data?.getSuppliers.suppliers, supplierContacts);
    const total = data?.getSuppliers.total ?? 0;
    const loading = loadingSuppliers || loadingColumns;

    return {
        dataGridProps: {
            rows,
            columns: configuredColumns,
            rowCount: total,
            onPaginationModelChange,
            paginationModel,
            onSortModelChange,
            initialState: { ...initialState, filter: {} },
            apiRef,
            sortModel,
            density,
            loading,
            rowSelectionModel,
            onRowSelectionModelChange: (newRowSelectionModel) => setRowSelectionModel(newRowSelectionModel),
            processRowUpdate,
            onProcessRowUpdateError,
            onColumnOrderChange,
            onColumnResize,
            onColumnVisibilityModelChange,
            onPinnedColumnsChange,
            pinnedColumns: isOnboarding
                ? { left: [SELECT_SUPPLIER_COLUMN_ID, NAME_COLUMN_ID, ONBOARDING_COLUMN_ID] }
                : (pinnedColumns ?? defaultPinnedColumns),
        },
        actionBarProps: {
            density,
            onDensityChange: setDensity,
            filter,
            onFilterChange: setFilterAndStoreState,
            onNameSearchChange: setNameSearch,
            selectedSuppliers: rowSelectionModel,
            onDeleteSuppliers,
            suppliersDataPollActive: Boolean(suppliersDataPollInterval),
            onToggleSuppliersDataPoll,
            total,
        },
        filter,
        setFilter,
        supplierTableMeta,
        views: views ?? [],
        refetchSupplierContacts,
    };
}

/**
 * Convert from the MUI sort model to the GraphQL sort model
 */
function toSortModel(sort: { columnId: string; direction: SortDirection } | undefined): GridSortModel {
    if (!sort) return [];
    return [
        {
            field: sort.columnId,
            sort: sort.direction === "ASC" ? "asc" : "desc",
        },
    ];
}
